import { render, staticRenderFns } from "./pingjia.vue?vue&type=template&id=24158b83&scoped=true"
import script from "./pingjia.vue?vue&type=script&lang=js"
export * from "./pingjia.vue?vue&type=script&lang=js"
import style0 from "./pingjia.vue?vue&type=style&index=0&id=24158b83&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24158b83",
  null
  
)

export default component.exports